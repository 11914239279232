<template>
  <div class="login-section" :class="$i18n.locale == 'en' ? 'en-style' : ''">
    <div class="uk-container">
      <div
        class="uk-child-width-1-1 uk-child-width-1-2@m uk-flex-middle"
        uk-grid
      >
        <div class="uk-form">
          <div class="form-border">
            <v-form lazy-validation ref="form" class="uk-form-stacked">
              <h4>{{ $t("form.changePassword") }}</h4>
              <!-- inpt 1 -->
              <div class="uk-margin">
                <div class="uk-form-controls">
                  <label>{{ $t("form.oldPassword") }}</label>
                  <v-text-field
                    v-model="oldPassword"
                    :rules="passwordRules"
                    :placeholder="$t('form.oldPassword')"
                    autocomplete="off"
                    autocorrect="off"
                    type="password"
                  ></v-text-field>
                </div>
              </div>
              <!-- end input 1 -->
              <!-- inpt 2 -->
              <div class="uk-margin">
                <div class="uk-form-controls">
                  <label>{{ $t("form.newPassword") }}</label>
                  <v-text-field
                    v-model="newPassword"
                    :rules="passwordRules"
                    :placeholder="$t('form.newPassword')"
                    autocomplete="off"
                    autocorrect="off"
                    type="password"
                  ></v-text-field>
                </div>
              </div>
              <!-- end input 2 -->
              <!-- inpt 3 -->
              <div class="uk-margin">
                <div class="uk-form-controls">
                  <label>{{ $t("form.confirmPassword") }}</label>
                  <v-text-field
                    v-model="passwordConfirm"
                    :rules="passwordConfirmRules"
                    :placeholder="$t('form.confirmPassword')"
                    autocomplete="off"
                    autocorrect="off"
                    type="password"
                  ></v-text-field>
                </div>
              </div>
              <!-- end input 3 -->
              <div class="uk-margin">
                <base-button
                  @click="changePasswordMethod()"
                  :options="{
                    block: true,
                    isLoading: loading,
                    color: this.primaryColor
                  }"
                >
                  {{ $t("form.changePassword") }}
                </base-button>
              </div>
            </v-form>
          </div>
        </div>
        <div class="uk-img">
          <img :src="loginImg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import BaseButton from "../../../core/Base/Buttons/BaseButton";

export default {
  name: "ChangePassword",

  components: { BaseButton },

  data() {
    return {
      /**
       * @author Hamza Sweid
       * @task #BACT-209
       * */
      loading: false,
      /**
       * @author Hamza Sweid
       * @task #BACT-209
       * */
      loginImg: require("../../../assets/img/login.png"),
      /**
       * @author Hamza Sweid
       * @task #BACT-209
       * */
      oldPassword: "",
      /**
       * @author Hamza Sweid
       * @task #BACT-209
       * */
      newPassword: "",
      /**
       * @author Hamza Sweid
       * @task #BACT-209
       * */
      passwordConfirm: "",
      /**
       * @desc check if password is correct
       * @author Hamza Sweid
       * @task #BACT-209
       * */
      passwordRules: [
        (v) => !!v || this.$t("required.password"),
        (v) => v.length >= 10 || this.$t("required.validPassword")
      ],
      passwordConfirmRules: [
        (v) => v === this.newPassword || this.$t("required.notIdentecalPassword")
      ]
    };
  },

  methods: {
    ...mapActions("Profile", ["changePassword"]),
    /**
     * @author Hamza Sweid
     * @task #BACT-209
     * */
    changePasswordMethod() {
      if (!this.$refs.form.validate()) return;
      this.loading = true;
      this.changePassword({
        old_password: this.oldPassword,
        new_password: this.newPassword
      })
        .then(() => {
          this.loading = false;
          setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 2000);
        })
        .catch(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style scoped>
.en-style {
  direction: ltr;
}
</style>
